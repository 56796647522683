import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

const videoFile = require('./../../images/video-bg.mp4');

const OurValue = () => {
    return (
        <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
            <div className="container">
                <div className="section-content">
                    <div className="row">
                        <div className="col-md-7 col-sm-6">
                          
                                <div 
                                    className="video-section-full bg-no-repeat bg-cover overlay-wraper m-b30" 
                                    style={{ position: 'relative', height: '400px', overflow: 'hidden' }} // Set a specific height for the box
                                >
                                    <div className="overlay-main bg-black opacity-04" />
                                    <ReactPlayer 
                                        url={videoFile}
                                        playing={true}
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        style={{ position: 'absolute', top: 0, left: 0 }} // Position it to cover the box
                                    />
                                    <div className="video-section-inner">
                                        <div className="video-section-content">
                                            <NavLink to={"#"} className="font-weight-600 text-uppercase" onClick={() => {}}>
                                                Video Presentation
                                            </NavLink>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-6">
                            {/* TITLE START */}
                            <div className="video-part-2">
                                <h3 style={{ color: 'black' }}>
                                    <span className="font-weight-100">We will provide</span> you the best work which you dreamt for!
                                </h3>
                                <p style={{ color: 'black' }}>
                                    "At Jain Life Spaces, we focus on creating environments where quality, comfort, and modern aesthetics come together. We’re dedicated to innovation and excellence, ensuring that every project enriches lives and inspires communities."
                                </p>
                                <ul className="list-angle-right anchor-line" style={{ color: 'black' }}>
                                    <li>Construction Management</li>
                                    <li>Pre Construction Services</li>
                                    <li>Contract Administration</li>
                                </ul>
                            </div>
                            {/* TITLE END */}
                            <div className="author-info author-info-v2 p-t20">
                                <div className="author-name">
                                    <h4 className="m-t0" style={{ color: 'black' }}>Sankesh Jain</h4>
                                    <p style={{ color: 'black' }}>CEO &amp; Founder</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurValue;
