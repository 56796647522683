import React from 'react';
import { NavLink } from 'react-router-dom';
import CountUp from 'react-countup';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const images = [
    require('./../../images/gallery/pic6.png'),
    require('./../../images/gallery/pic9.png'),
    require('./../../images/gallery/pic2.png'),
];

const bnr1 = require('./../../images/background/bg-map.png');

const About2 = () => {
    const options = {
        loop: true,
        autoplay: true,
        margin: 20,
        dots: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
        },
    };

    const highlights = [
        "Uncompromised Quality: Crafted with high standards.",
        "Customer-Centric: Built around what matters to you.",
    ];

    const transitionStyles = {
        enter: { opacity: 0, transform: 'translateY(-10px)' },
        enterActive: { opacity: 1, transform: 'translateY(0)', transition: 'opacity 500ms, transform 500ms' },
        exit: { opacity: 1 },
        exitActive: { opacity: 0, transition: 'opacity 500ms' },
    };

    return (
        <div className="section-full" style={{ backgroundImage: `url(${bnr1})`, backgroundSize: 'cover', padding: '60px 0' }}>
            <div className="container">
                <div className="section-head">
                    <h2 className="text-uppercase text-center" style={{ color: '#333', marginBottom: '30px', fontWeight: '600' }}>
                        About <span style={{ color: '#fff' }}>Jain Life Spaces</span>
                    </h2>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-8 col-md-12 mb-4">
                        <OwlCarousel className="owl-theme" {...options}>
                            {images.map((item, index) => (
                                <div className="item" key={index}>
                                    <NavLink to="/about">
                                        <img src={item} alt="" className="img-fluid rounded" style={{ boxShadow: '0px 4px 8px rgba(0,0,0,0.2)' }} />
                                    </NavLink>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>

                    <div className="col-lg-4 col-md-12 text-white" style={{ backgroundColor: '#212529', padding: '30px', borderRadius: '8px' }}>
                        <h3 style={{ color: '#fff', fontWeight: '600' }}>Committed to Superior Quality & Results</h3>
                        <p style={{ color: '#eaeaea', fontSize: '15px', lineHeight: '1.6' }}>
                            <strong>Turning Vision into Value</strong><br />
                            At Jain Lifespaces, we go beyond constructing properties; we create vibrant communities that embody aspirations. With each project, we prioritize quality, transparency, and integrity.
                        </p>
                        <h4 style={{ color: '#fff', marginTop: '20px' }}>Why Choose Us?</h4>
                        <TransitionGroup component="ul" className="list-unstyled" style={{ paddingLeft: 0 }}>
                            {highlights.map((highlight, index) => (
                                <CSSTransition
                                    key={index}
                                    timeout={500}
                                    classNames="fade"
                                    onEnter={(node) => Object.assign(node.style, transitionStyles.enter)}
                                    onEntering={(node) => Object.assign(node.style, transitionStyles.enterActive)}
                                    onExit={(node) => Object.assign(node.style, transitionStyles.exit)}
                                    onExiting={(node) => Object.assign(node.style, transitionStyles.exitActive)}
                                >
                                    <li style={{ display: 'flex', alignItems: 'center', color: '#eaeaea', marginBottom: '10px' }}>
                                        <i className="fa fa-check-circle" style={{ color: '#fff', marginRight: '10px' }}></i>
                                        {highlight}
                                    </li>
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                        <p style={{ color: '#eaeaea', marginTop: '20px', fontSize: '15px' }}>
                            Join us in crafting spaces that inspire and uplift. Discover the Jain Lifespaces difference—a legacy of trust and excellence in building futures.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About2;
