import React from 'react';
import Header from './../Common/Header2';
import Footer from './../Common/Footer2';
import About4 from './../Elements/About2';

const bnrimg = require('./../../images/banner/1.png');

const About = () => {
    return (
        <>
            <Header />
            <div className="page-content">
                <div 
                    className="banner" 
                    style={{
                        position: 'relative',
                        backgroundImage: `url(${bnrimg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '300px', // Adjust height as needed
                        opacity: 0.9, // Set opacity for the image
                    }}
                >
                    <div 
                        className="banner-content" 
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                            zIndex: 1, // To ensure text appears above the background
                            color: 'white',
                        }}
                    >
                        <h1 style={{ color: 'white' }}>Creating quality urban lifestyles, building stronger communities.</h1>
                        <p>About Us</p>
                    </div>
                </div>
                <About4 />
            </div>
            <Footer />
        </>
    );
};

export default About;
