import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = ({ bgcolor }) => {

    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', () => {
                    resolve();
                });
                script.addEventListener('error', (e) => {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        };

        loadScript('./assets/js/masonary.js');
    }, []);

    return (
        <div className={`header-nav navbar-collapse collapse ${bgcolor || ''}`}>
            <ul className="nav navbar-nav">
                <li className="active">
                    <NavLink to={"/"}>Home</NavLink>
                </li>
                <li>
                    <NavLink to={"/project-grid"}>Projects</NavLink>
                </li>
                <li>
                    <NavLink to={"/about"}>About Us</NavLink>
                </li>
                <li>
                    <NavLink to={"/contactus"}>Contact Us</NavLink>
                </li>
              
            </ul>
        </div>
    );
};

export default Navigation;
