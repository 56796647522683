import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home2 from './Pages/Home2';
import About from './Pages/About';
import ContactUs from './Pages/ContactUs';
import ProjectGrid from './Pages/ProjectGrid';
import ProjectDetail from './Pages/ProjectDetail'; // Import your ProjectDetail component
import ScrollToTop from './Common/ScrollToTop';

const Components = () => {
    return (
        <BrowserRouter basename="/">
            <ScrollToTop />
            <div className="page-wraper">
                <Routes>
                    <Route path="/" element={<Home2 />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contactus" element={<ContactUs />} />
                    <Route path="/project-grid" element={<ProjectGrid />} />
                    <Route path="/project-detail/:id" element={<ProjectDetail />} /> {/* Add this route */}
                    
                </Routes>
            </div>
        </BrowserRouter>
    );
};

export default Components;
