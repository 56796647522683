import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './../Common/Header2';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';
import ReactPlayer from 'react-player';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon from react-icons
import baseURL from '../../baseURL';

const bnrimg = require('./../../images/banner/5.jpg');

const ProjectDetail = () => {
    const { id } = useParams();
    const [projectDetails, setProjectDetails] = useState(null);
    const [activeTab, setActiveTab] = useState('images'); // State to manage active tab

    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                const response = await fetch(`${baseURL}/projects/${id}`);
                if (!response.ok) throw new Error('Failed to fetch project details');
                const data = await response.json();
                setProjectDetails(data);
            } catch (error) {
                console.error('Error fetching project details:', error);
            }
        };

        fetchProjectDetails();
    }, [id]);

    if (!projectDetails) {
        return <div>Loading...</div>;
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // Inline CSS Styles
    const tabStyles = {
        container: {
            marginBottom: '20px',
        },
        tabList: {
            border: 'none',
            display: 'flex',
            listStyleType: 'none',
            padding: '0',
            marginTop: '100px',
        },
        tabItem: {
            marginRight: '10px',
        },
        tabLink: {
            color: 'white',
            backgroundColor: '#333',
            borderRadius: '5px 5px 0 0',
            padding: '10px 20px',
            fontSize: '16px',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease, color 0.3s ease',
            cursor: 'pointer',
        },
        activeTab: {
            backgroundColor: '#ff6600',
            color: 'white',
            borderBottom: '3px solid #ff6600',
        },
        tabContent: {
            padding: '20px',
            backgroundColor: '#222',
            borderRadius: '0 0 5px 5px',
        },
        title: {
            color: 'white', // White color for title
            fontSize: '32px',
            fontWeight: 'bold',
            marginBottom: '15px',
        },
        description: {
            color: 'white', // White color for description
            fontSize: '16px',
            lineHeight: '1.6',
        },
        contactSection: {
            marginTop: '20px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px', // Add margin bottom to create space below WhatsApp icon
        },
        contactNumber: {
            color: 'white',
            fontSize: '18px',
            marginLeft: '10px',
        },
        whatsappIcon: {
            fontSize: '24px',
            color: '#25d366', // WhatsApp green color
            cursor: 'pointer',
        },
    };

    return (
        <>
            <Header />
            <div className="page-content" style={{ backgroundColor: 'black', color: 'white' }}>
                <Banner title={projectDetails.title} pagename="Project Detail" bgimage={bnrimg} />
                
                <div className="section-full p-tb80 inner-page-padding">
                    <div className="container">
                        <div className="project-detail-outer">
                            <div className="m-b0">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* Project Title */}
                                        <h1 style={tabStyles.title}>{projectDetails.title}</h1>

                                       {/* Project Description */}
<p style={{ 
    ...tabStyles.description,
    whiteSpace: 'normal', // Allows wrapping of text
    wordWrap: 'break-word', // Breaks long words if necessary
    wordBreak: 'break-word', // Ensures words break at the end of the line
}}>
    {projectDetails.description}
</p>

                                        <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
    <FaWhatsapp style={{ fontSize: '24px', color: '#25d366', cursor: 'pointer' }} />
    <span style={{ fontSize: '18px', color: 'white', marginLeft: '10px', fontWeight: 'bold' }}>
        {projectDetails.contactNumber}
    </span>
    <a 
        href={`https://wa.me/9241292412`} 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ marginLeft: '10px', textDecoration: 'none' }}
    >
        <span style={{ fontSize: '18px', color: 'white' }}>Contact on WhatsApp</span>
    </a>
</div>




                                        {/* Tab Navigation */}
                                        <ul style={tabStyles.tabList}>
                                            <li style={tabStyles.tabItem}>
                                                <a
                                                    href="#"
                                                    style={{
                                                        ...tabStyles.tabLink,
                                                        ...(activeTab === 'images' ? tabStyles.activeTab : {}),
                                                    }}
                                                    onClick={() => handleTabClick('images')}
                                                >
                                                    Project Images
                                                </a>
                                            </li>
                                            <li style={tabStyles.tabItem}>
                                                <a
                                                    href="#"
                                                    style={{
                                                        ...tabStyles.tabLink,
                                                        ...(activeTab === 'floorPlans' ? tabStyles.activeTab : {}),
                                                    }}
                                                    onClick={() => handleTabClick('floorPlans')}
                                                >
                                                    Floor Plans
                                                </a>
                                            </li>
                                        </ul>

                                        {/* Tab Content */}
                                        <div style={tabStyles.tabContent}>
                                            {/* Project Images */}
                                            {activeTab === 'images' && (
                                                <div>
                                                    <div className="row">
                                                        {projectDetails.images.map((image, index) => (
                                                            <div key={index} className="col-md-4 col-sm-4">
                                                                <div className="project-detail-pic m-b30">
                                                                    <div className="mt-media">
                                                                        <img
                                                                            src={image}
                                                                            alt={`Project Image ${index + 1}`}
                                                                            style={{ width: '100%', height: 'auto' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Floor Plans */}
                                            {activeTab === 'floorPlans' && (
                                                <div>
                                                    <div className="row">
                                                        {projectDetails.floorPlans.map((floorPlan, index) => (
                                                            <div key={index} className="col-md-4 col-sm-4">
                                                                <div className="project-detail-pic m-b30">
                                                                    <div className="mt-media">
                                                                        <img
                                                                            src={floorPlan}
                                                                            alt={`Floor Plan ${index + 1}`}
                                                                            style={{ width: '100%', height: 'auto' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="project-detail-containt">
                                {/* Additional content, such as project description */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="myModal3" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url={projectDetails.videoUrl} />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default ProjectDetail;
