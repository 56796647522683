import React from 'react';

// const services = [
//     {
//         count: 1,
//         title: 'Architecture Design',
//         icon: require('./../../images/icon/compass.png'),
//         image: require('./../../images/services/1.png'),
//         description: 'Crafting spaces that blend functionality with aesthetic innovation, architecture design brings visions to life by shaping environments that inspire and endure.',
//     },
//     {
//         count: 2,
//         title: 'Building Construction',
//         icon: require('./../../images/icon/crane-1.png'),
//         image: require('./../../images/services/3.png'),
//         description: 'Building construction transforms concepts into lasting structures, combining strength, precision, and quality to create safe and sustainable spaces for the future.',
//     },
//     {
//         count: 3,
//         title: 'Modern Technique',
//         icon: require('./../../images/icon/renovation.png'),
//         image: require('./../../images/services/2.png'),
//         description: 'Modern construction techniques integrate advanced technology with innovative materials, creating efficient, sustainable, and resilient structures for today�s dynamic environments.',
//     }   
// ];

const Services = () => {
    // return (
    //     <div className="section-full bg-white slider-half-part">
    //         <div className="container" style={{ backgroundColor: 'white', border: 'none' }}> {/* Added inline style */}
    //             <div className="section-content">
    //                 <div className="row">
    //                     {services.map((item, index) => (
    //                         <div className="col-md-4 col-sm-4 m-b30" key={index}>
    //                             <div className="half-blocks">
    //                                 <div className="mt-icon-box-wraper center m-b30">
    //                                     <div className="half-block-content icon-count-2 p-a30 p-tb50" style={{ color: 'black' }}>
    //                                         <div className="mt-icon-box-lg radius bg-gray m-b20 scale-in-center">
    //                                             <span className="icon-cell"><img src={item.icon} alt="icon" /></span>
    //                                         </div>
    //                                         <div className="icon-content">
    //                                             <h4 className="mt-tilte m-b25">{item.title}</h4>
    //                                             <p>{item.description}</p>
    //                                         </div>
    //                                     </div>
    //                                     <div className="bg-cover half-blocks-bg" style={{ backgroundImage: `url(${item.image})` }} />
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     ))}
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default Services;
