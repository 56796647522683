import React from 'react';
import Header from './../Common/Header2';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';
import InquiryForm from './InquiryForm';  // Import the InquiryForm component

const bnrimg = require('./../../images/banner/3.png');

const ContactUs = () => {
    return (
        <>
            <Header />
            <div className="page-content">
            <Banner 
    title={<span style={{ color: 'white' }}>Putting a plan to action, to assure your satisfaction!</span>} 
    pagename="Contact Us" 
    bgimage={bnrimg} 
/>

                {/* SECTION CONTENT START */}
                <div className="section-full p-tb80 inner-page-padding">
                    {/* LOCATION BLOCK */}
                    <div className="container">
                        {/* GOOGLE MAP & CONTACT FORM */}
                        <div className="section-content">
                            {/* CONTACT FORM */}
                            <div className="row">
                                <div className="col-md-8 col-sm-6">
                                    <InquiryForm />  {/* Render the InquiryForm component here */}
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="contact-info m-b30">
                                        {/* TITLE START */}
                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-left">
                                                <div className="mt-separator">
                                                    <h2 className="text-uppercase sep-line-one ">
                                                        <span className="font-weight-300 text-primary">Contact</span> Info
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="bg-dark p-a20 text-white">
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                    <p>+91-9241292412</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Email address</h5>
                                                    <p>jainlifespaces@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left">
                                                <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Address info</h5>
                                                    <p>Jain Lifespaces Properties LLP</p>
                                                    <p>#5f2, 5th Floor, Sampurna Chambers, Vasavi Temple Road</p>
                                                    <p>Basavanagudi, Bengaluru, Karnataka 560004</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gmap-outline" style={{ marginTop: '30px' }}>
                            <div style={{ height: '400px', width: '100%' }}>
                                <iframe
                                    title="office-location-map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.5277621246017!2d77.57668302045626!3d12.949758367422556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e2a616f1c1%3A0x8dc908c7e558e287!2sJain%20Lifespaces%20Properties%20LLP%2C%20Sampurna%20Chambers%2C%2050%2F5%2013%2C%20Vasavi%20Temple%20St%2C%20Vishweshwarapura%2C%20Basavanagudi%2C%20Bengaluru%2C%20Karnataka%20560004!5e0!3m2!1sen!2sin!4v1698834639438!5m2!1sen!2sin"
                                    width="100%"
                                    height="400"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                {/* SECTION CONTENT END */}
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;
