import React, { useState, useEffect } from 'react';
import Header2 from './../Common/Header2';
import Footer2 from './../Common/Footer2';
import Slider2 from '../Elements/Slider2';
import Services from '../Elements/Services';
import About2 from '../Elements/About2';
import OurServices2 from '../Elements/OurServices2';
import OurValue2 from './ourvalues';

const Home2 = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        // Show the popup when the component is mounted
        setShowPopup(true);
    }, []);

    const handleAllow = () => {
        // Redirect to WhatsApp community link
        window.open('https://chat.whatsapp.com/LF6Issi2PjhLcLBs6a7jhm', '_blank');
        setShowPopup(false);  // Close the popup after allowing
    };

    const handleDeny = () => {
        setShowPopup(false);  // Close the popup if user denies
    };

    return (
        <>
            <Header2 />
            <div className="page-content">
                <Slider2 />
                <Services />
                <About2 />
                <OurValue2 />
                {/* <OurServices2 /> */}

                {/* Popup */}
                {showPopup && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Dark background for better contrast
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999
                    }}>
                        <div style={{
                            backgroundColor: '#fff',  // White background for popup
                            padding: '20px',
                            borderRadius: '8px',
                            textAlign: 'center',
                            width: '300px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  // Light shadow for better elevation
                        }}>
                            <h4 style={{ color: '#000' }}>We would like to send you our latest updates and offers on our projects</h4>
                            <div style={{
                                marginTop: '20px'
                            }}>
                                <button
                                    style={{
                                        padding: '10px 20px',
                                        margin: '5px',
                                        cursor: 'pointer',
                                        border: '1px solid #000',  // Border to match black theme
                                        borderRadius: '5px',
                                        fontSize: '16px',
                                        backgroundColor: '#fff', // White button background
                                        color: '#000', // Black text
                                        transition: 'background-color 0.3s, color 0.3s',  // Smooth transition for hover effect
                                    }}
                                    onClick={handleAllow}
                                    onMouseOver={(e) => {
                                        e.target.style.backgroundColor = '#000';  // Black background on hover
                                        e.target.style.color = '#fff';  // White text on hover
                                    }}
                                    onMouseOut={(e) => {
                                        e.target.style.backgroundColor = '#fff';  // Revert to white
                                        e.target.style.color = '#000';  // Revert to black text
                                    }}
                                >
                                    Allow
                                </button>
                                <button
                                    style={{
                                        padding: '10px 20px',
                                        margin: '5px',
                                        cursor: 'pointer',
                                        border: '1px solid #000',  // Border to match black theme
                                        borderRadius: '5px',
                                        fontSize: '16px',
                                        backgroundColor: '#000',  // Black button background
                                        color: '#fff',  // White text
                                        transition: 'background-color 0.3s, color 0.3s',  // Smooth transition for hover effect
                                    }}
                                    onClick={handleDeny}
                                    onMouseOver={(e) => {
                                        e.target.style.backgroundColor = '#fff';  // White background on hover
                                        e.target.style.color = '#000';  // Black text on hover
                                    }}
                                    onMouseOut={(e) => {
                                        e.target.style.backgroundColor = '#000';  // Revert to black
                                        e.target.style.color = '#fff';  // Revert to white text
                                    }}
                                >
                                    Don't Allow
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer2 />
        </>
    );
};

export default Home2;

