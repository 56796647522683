import React, { useEffect, useState } from 'react';
import slideImage1 from './../../images/main-slider/slider2/slide1.png';
import slideImage2 from './../../images/main-slider/slider2/slide2.png';
import slideImage3 from './../../images/main-slider/slider2/slide3.png';

const slides = [slideImage1, slideImage2, slideImage3];

const Slider2 = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Handle window resizing
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    // Inline styles
    const sliderContainerStyle = {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        maxWidth: windowWidth > 768 ? '1200px' : '100%', // Adjust max width based on screen size
        margin: 'auto',
    };

    const sliderStyle = {
        display: 'flex',
        transition: 'transform 0.5s ease-in-out',
        transform: `translateX(-${currentSlide * 100}%)`,
    };

    const slideStyle = {
        minWidth: '100%', // Each slide takes full width
    };

    const buttonStyle = {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'rgba(255, 255, 255, 0.7)',
        color: 'black', // Change text color to black
        border: 'none',
        fontSize: windowWidth > 768 ? '2rem' : '1.5rem', // Smaller font size for smaller screens
        cursor: 'pointer',
        zIndex: 10,
        padding: '10px', // Optional padding for better clickability
    };

    const prevButtonStyle = {
        ...buttonStyle,
        left: '10px',
    };

    const nextButtonStyle = {
        ...buttonStyle,
        right: '10px',
    };

    return (
        <div style={sliderContainerStyle}>
            <div style={sliderStyle}>
                {slides.map((slide, index) => (
                    <div key={index} style={slideStyle}>
                        <img src={slide} alt={`Slide ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                    </div>
                ))}
            </div>
            <button style={prevButtonStyle} onClick={prevSlide}>❮</button>
            <button style={nextButtonStyle} onClick={nextSlide}>❯</button>
        </div>
    );
};

export default Slider2;

