import React from 'react';
import { NavLink } from 'react-router-dom';

const Banner = ({ bgimage, title, pagename }) => {
    return (
        <div
            className="mt-bnr-inr overlay-wraper bg-parallax bg-top-center"
            data-stellar-background-ratio="0.5"
            style={{ backgroundImage: `url(${bgimage})` }}
        >
            <div className="overlay-main bg-black opacity-07" />
            <div className="container">
                <div className="mt-bnr-inr-entry">
                <div >
                        <div className="banner-title-name">
                            <h2 className="m-b0" style={{ color: 'black' }}>{title}</h2> {/* Changed color to black */}
                        </div>
                    </div>
                    {/* BREADCRUMB ROW */}
                    <div>
                        <ul className="mt-breadcrumb breadcrumb-style-2">
                            <li><NavLink to="./" style={{ color: 'black' }}>Home</NavLink></li> {/* Changed color to black */}
                            <li style={{ color: 'black' }}>{pagename}</li> {/* Changed color to black */}
                        </ul>
                    </div>
                    {/* BREADCRUMB ROW END */}
                </div>
            </div>
        </div>
    );
};

export default Banner;
