import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Header from './../Common/Header2';
import Footer from './../Common/Footer2';
import InquiryForm from './../Pages/InquiryForm';
import baseURL from '../../baseURL';

const bnrimg = require('./../../images/banner/2.png');

const ProjectGrid = () => {
    const [projects, setProjects] = useState([]);
    const [showInquiryForm, setShowInquiryForm] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [inquirySubmitted, setInquirySubmitted] = useState(false);
    const [showPopup, setShowPopup] = useState(true); // State to control the popup
    const navigate = useNavigate(); // Use useNavigate for redirection

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${baseURL}/projects`);
                if (!response.ok) throw new Error('Network response was not ok');
                
                const data = await response.json();
                setProjects(data);
                console.log(data);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();

        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', resolve);
                script.addEventListener('error', reject);
                document.body.appendChild(script);
            });
        };

        loadScript('./assets/js/masonary.js');
    }, []);

    const handleReadMoreClick = (projectId) => {
        setSelectedProjectId(projectId);
        if (!inquirySubmitted) setShowInquiryForm(true);
    };

    const handleInquirySuccess = () => {
        setInquirySubmitted(true);
        setShowInquiryForm(false);
        navigate(`/project-detail/${selectedProjectId}`); // Redirect to Project Detail page using selectedProjectId
    };

    // Handle the "Allow" button click for the popup
    const handleAllowClick = () => {
        window.location.href = 'https://chat.whatsapp.com/Lt18DcwmwYFCLpsK67SE7d'; // Redirect to WhatsApp group
    };

    // Handle the "Don't Allow" button click for the popup
    const handleDontAllowClick = () => {
        setShowPopup(false); // Hide the popup
    };

    return (
        <>
            <Header />
            <div className="page-content">
                <div 
                    className="banner" 
                    style={{
                        position: 'relative',
                        backgroundImage: `url(${bnrimg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '300px', // Adjust height as needed
                        opacity: 0.9, // Set opacity for the image
                    }}
                >
                    <div 
                        className="banner-content" 
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                            zIndex: 1, // To ensure text appears above the background
                            color: 'white',
                        }}
                    >
                        <h1 style={{ color: 'white' }}>Always dedicated and devoted</h1>
                        <p style={{ color: 'white' }}>Projects</p>
                    </div>
                </div>

                {/* SECTION CONTENT START */}
                <div className="section-full p-tb80 inner-page-padding">
                    <div className="container">
                        {/* GALLERY CONTENT START */}
                        <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                            {projects.map((project, index) => (
                                <div 
                                    key={index} 
                                    className={`${project.filter} masonry-item col-md-4 col-sm-6 m-b30`}
                                >
                                    <div className="image-effect-two hover-shadow">
                                        <img src={project.images[0]} alt={project.title} />
                                        <div className="figcaption" style={{ color: 'white' }}>
                                            <h4 className="mt-tilte">{project.title}</h4>
                                            <p>{project.description}</p>
                                            <button 
                                                onClick={() => handleReadMoreClick(project._id)} 
                                                className="read-more site-button btn-effect"
                                                style={{ backgroundColor: 'black', color: 'white' }}
                                            >
                                                Read More
                                            </button>
                                            <a className="mfp-link" href={project.images[0]}>
                                                <i className="fa fa-arrows-alt" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* GALLERY CONTENT END */}
                        
                        <div className="m-t50 text-center">
                            <NavLink 
                                to={"#"} 
                                className="site-button btn-effect" 
                                style={{ backgroundColor: 'black', color: 'white' }}
                            >
                                Load More
                            </NavLink>
                        </div>
                    </div>
                </div>
                {/* SECTION CONTENT END */}
            </div>

            {/* Centered Inquiry Form Modal */}
            {showInquiryForm && (
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '500px',
                    padding: '20px',
                    backgroundColor: '#fff',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px',
                    zIndex: '1000'
                }}>
                    <InquiryForm 
                        projectId={selectedProjectId}
                        onSuccess={handleInquirySuccess} 
                        onClose={() => setShowInquiryForm(false)}
                    />
                </div>
            )}

            {/* Optional Overlay */}
            {showInquiryForm && (
                <div 
                    onClick={() => setShowInquiryForm(false)}
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '999'
                    }}
                />
            )}

            {/* Popup for WhatsApp community */}
            {showPopup && (
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: '400px',
                    padding: '20px',
                    backgroundColor: '#fff',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px',
                    zIndex: '1000',
                    textAlign: 'center'
                }}>
                    <h3 style={{ color: 'black' }}>We would like to send you the latest updates and offers on our projects.</h3>
                    <div style={{ marginTop: '20px' }}>
                        <button 
                            onClick={handleAllowClick} 
                            style={{
                                backgroundColor: 'black',
                                color: 'white',
                                padding: '10px 20px',
                                marginRight: '10px',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Allow
                        </button>
                        <button 
                            onClick={handleDontAllowClick} 
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                padding: '10px 20px',
                                border: '1px solid black',
                                cursor: 'pointer',
                            }}
                        >
                            Don't Allow
                        </button>
                    </div>
                </div>
            )}

            {/* Optional Overlay for the Popup */}
            {showPopup && (
                <div 
                    onClick={handleDontAllowClick}
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '999'
                    }}
                />
            )}

            <Footer />
        </>
    );
};

export default ProjectGrid;
