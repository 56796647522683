import React from 'react';

const services = [
    {
        count: 1,
        title: 'Building Construction',
        icon: require('./../../images/icon/crane-1.png'),
        description: 'Jain Life Spaces specializes in high-quality construction, ensuring projects meet the highest standards of excellence and durability.',
    },
    {
        count: 2,
        title: 'Building Renovation',
        icon: require('./../../images/icon/renovation.png'),
        description: 'Transforming existing spaces with innovative designs and modern functionality to meet contemporary needs and aesthetics.',
    },
    {
        count: 3,
        title: 'Building Maintenance',
        icon: require('./../../images/icon/toolbox.png'),
        description: 'Providing ongoing maintenance to ensure the longevity and optimal performance of your building assets.',
    },
    {
        count: 4,
        title: 'Architecture Design',
        icon: require('./../../images/icon/compass.png'),
        description: 'Crafting creative and functional architectural designs that bring visionary ideas to life with precision and artistry.',
    },
];

const img1 = require('./../../images/background/ptn-1.png');

const OurServices2 = () => {
    return (
        <div className="section-full p-t80 p-b50 bg-white mobile-page-padding" style={{ backgroundImage: `url(${img1})` }}>
            <div className="section-content">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head m-b50">
                        <div className="mt-separator-outer separator-left">
                            <div className="mt-separator">
                                <h2 className="text-uppercase sep-line-one">
                                    <span className="font-weight-300" style={{ color: 'black' }}>Our</span> Services
                                </h2>
                            </div>
                        </div>
                        <h3 style={{ color: 'black' }}>
                            At Jain Life Spaces, we transform visions into reality, delivering exceptional quality and innovative solutions in every project we undertake.
                        </h3>
                    </div>
                    {/* TITLE END */}
                    <div className="row services-v2 no-col-gap">
                        {services.map((item, index) => (
                            <div className="col-md-3 col-sm-6 col-xs-6 col-xs-100pc" key={index}>
                                <div className="mt-icon-box-wraper bdr-l-1 bdr-solid bdr-gray">
                                    <div className="relative p-a30">
                                        <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                                            <span className="icon-cell">
                                                <img src={item.icon} alt={item.title} />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="mt-tilte m-b25" style={{ color: 'black' }}>{item.title}</h4>
                                            <p style={{ color: 'black' }}>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurServices2;
