import React, { useState } from 'react';
import baseURL from '../../baseURL';

const InquiryForm = ({ projectId, onSuccess, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setError(''); // Clear error when user starts typing
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${baseURL}/inquiries`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    name: formData.name, 
                    mobileNo: formData.mobile, 
                    projectId 
                }),
            });

            if (!response.ok) {
                const errorData = await response.json(); // Attempt to get error details
                throw new Error(errorData.message || 'Network response was not ok');
            }

            setSuccess(true);
            onSuccess(); // Call the success handler to indicate success
        } catch (err) {
            setError('There was a problem submitting your inquiry: ' + err.message); // Show the specific error message
            console.error('Error submitting inquiry:', err);
        }
    };

    return (
        <div style={{
            maxWidth: '400px',
            margin: 'auto',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#fff'
        }}>
            <h2 style={{
                fontSize: '24px',
                fontWeight: '600',
                color: 'black', // Changed text color to black
                textAlign: 'center',
                marginBottom: '20px'
            }}>Inquiry Form</h2>
            
            {success ? (
                <div style={{
                    color: '#4CAF50',
                    fontWeight: '500',
                    textAlign: 'center',
                    margin: '15px 0'
                }}>
                    Thank you for your inquiry! We will get back to you soon.
                </div>
            ) : (
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ 
                            display: 'block', 
                            fontWeight: 'bold', 
                            color: 'black', // Changed label text color to black
                            marginBottom: '5px' 
                        }}>
                            Name:
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                fontSize: '16px'
                            }}
                        />
                    </div>
                    
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ 
                            display: 'block', 
                            fontWeight: 'bold', 
                            color: 'black', // Changed label text color to black
                            marginBottom: '5px' 
                        }}>
                            Mobile No:
                        </label>
                        <input
                            type="tel"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                fontSize: '16px'
                            }}
                        />
                    </div>

                    {error && (
                        <div style={{
                            color: '#D8000C',
                            backgroundColor: '#FFBABA',
                            padding: '10px',
                            borderRadius: '4px',
                            marginBottom: '15px',
                            fontSize: '14px'
                        }}>{error}</div>
                    )}

                    <button type="submit" style={{
                        padding: '12px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#fff',
                        backgroundColor: 'black',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginBottom: '10px'
                    }}>
                        Submit Inquiry
                    </button>
                    
                    <button type="button" onClick={onClose} style={{
                        padding: '12px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: 'white', // Changed text color to black
                        backgroundColor: 'black',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}>
                        Cancel
                    </button>
                </form>
            )}
        </div>
    );
};

export default InquiryForm;
